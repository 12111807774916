$(function() {    
    var $html = $("html");
    var $wcagContrastButton = $(".pcg-btn-wcag-contrast");
    var $wcagSizeButton = $(".pcg-btn-wcag-size");
    var $cookiesCloseButton = $('.cookies-info-close');
    var COOKIES_INFO_KEY = 'cookies-info';
    var WCAG_CONTRAST_KEY = 'wcag-contrast';
    var WCAG_CONTRAST_CLASS =  'pcg-wcag-contrast-green-black';
    var WCAG_SIZE_KEY = 'wcag-size';
    var WCAG_SIZE_CLASS_0  = 'text-size-0';
    var WCAG_SIZE_CLASS_1  = 'text-size-1';
    var WCAG_SIZE_CLASS_2  = 'text-size-2';
    var SIZE_ARRAY = [  WCAG_SIZE_CLASS_1, WCAG_SIZE_CLASS_2, WCAG_SIZE_CLASS_0];
   
    $wcagContrastButton.click(function(){
        $html.toggleClass(WCAG_CONTRAST_CLASS);
        Cookies.set(WCAG_CONTRAST_KEY, $html.hasClass(WCAG_CONTRAST_CLASS), { expires: 1000 });
    });

    $cookiesCloseButton.click(function () {
        $('.cookies-info-panel').addClass('hide');
        Cookies.set(COOKIES_INFO_KEY, 'true', { expires: 1000 }); 
    });
    
    $wcagSizeButton.click(function(){
        $html.removeClass(WCAG_SIZE_KEY);
        $html.removeClass(WCAG_SIZE_CLASS_1);
        $html.removeClass(WCAG_SIZE_CLASS_2);
  
        size  = SIZE_ARRAY.shift();
        SIZE_ARRAY.push(size);
        $html.addClass(size);

        Cookies.set(WCAG_SIZE_KEY, size, { expires: 1000 });
    });
 


    $(".pcg-notification-group").click(function () {
        $(".pcg-notification-container").toggleClass("pcg-notification-open");
    });
    $(document).on("click", function(e) {
        if ($(e.target).is(".pcg-icon-bell") === false) {
          $(".pcg-notification-container").removeClass("pcg-notification-open");
        }
    });

    $(".pcg-message-group").click(function () {
        $(".pcg-message-container").toggleClass("pcg-message-open");
    });
    $(document).on("click", function(e) {
        if ($(e.target).is(".pcg-icon-envelope") === false) {
          $(".pcg-message-container").removeClass("pcg-message-open");
        }
    });

    $(".pcg-profile-group").click(function (e) {
        $(this).toggleClass("show");
        var $section = $('.pcg-sidebar-container');
        $section.removeClass("open-menu");
        $(".pcg-content").removeClass("mobile-dimmed"); 
        e.stopPropagation();
    });
    $(document).on("click", function(e) {
        if ($(e.target).is(".pcg-profile-btn, .pcg-icon-profile-arrow, .pcg-profile-btn-img, .pcg-profile-img") === false) {
            $(".pcg-profile-group").removeClass("show");
        }
    });

    $('[data-toggle="popover"]').popover({
        trigger: 'focus'
    });
    $('[data-toggle="tooltip"]').tooltip({
        template: '<div class="tooltip pcg-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    });

    $(".pcg-languages-list").click(function () {
        $(this).toggleClass("show");
    });
    
    $(document).on("click", function(e) {
        if ($(e.target).is(".pcg-languages-list, .lenguage-icon, .selected-language-name, .language-arrow") === false) {
            $(".pcg-languages-list").removeClass("show");
        }
    });
});

$(".pcg-menu-btn").click(function (e) {
    $section = $('.pcg-sidebar-container');
    $section.toggleClass("open-menu");
    $(".pcg-content").toggleClass("mobile-dimmed");
    $(".pcg-page-container").removeClass("slim"); 
    e.stopPropagation();
});

$(".pcg-content, .pcg-top-container, .pcg-navbar-container").click(function (e) {
    $section = $('.pcg-sidebar-container');
    if($section.hasClass("open-menu"))
	{ 	
        $section.removeClass("open-menu");
        $(".pcg-content").removeClass("mobile-dimmed"); 
	}
}); 

$(".pcg-sidebar-section-header").click(function () {
    $section = $(this);
    $section.toggleClass("active");
});

$(".pcg-section-opened").click(function () {
    $section = $(this).parent().parent();
    $section.removeClass("pcg-section-active");
});
$(".pcg-section-closed").click(function () {
    $section = $(this).parent().parent();
    $section.addClass("pcg-section-active");
});

$(".pcg-table-section .pcg-section-header").click(function () {
    $(this).parents(".pcg-table-section").toggleClass("pcg-table-section-active");
});

$(".pcg-btn-sidebar-menu").click(function () {
    $section = $(this);
    $section.toggleClass("hidden");
});
 
function expandCollapsePanel(section){ 
    section.parents(".pcg-panel").toggleClass("pcg-panel-expanded"); 
    section.parents(".pcg-panel").toggleClass("pcg-panel-collapsed"); 
    section.find(".pcg-icon").toggleClass("pcg-icon-arrow-up"); 
    section.find(".pcg-icon").toggleClass("pcg-icon-arrow-down"); 
} 
 
$(".pcg-panel>.pcg-panel-header ").click(function(){ 
    $section = $(this).find(".pcg-action-collapse"); 
    expandCollapsePanel($section); 
}); 

 $(".pcg-action-priority .pcg-action-priority-button").click(function(e){ 
    e.stopPropagation();
}); 

var firstPanel = $(".pcg-panel .pcg-action-collapse").first(); 
expandCollapsePanel(firstPanel); 